import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DonationTarget } from 'src/app/interfaces/donations';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-organisation-description-en',
  templateUrl: './organisation-description-en.component.html',
  styleUrls: ['./organisation-description-en.component.scss']
})
export class OrganisationDescriptionEnComponent implements OnInit {

  DonationTarget = DonationTarget;
    urlParamType: string = '';
    contentType: string = '';

    constructor(private activatedRoute: ActivatedRoute, public router: Router, public firebaseService: FirebaseService) {
        this.getParams();
    }

    ngOnInit(): void {
      console.log('here');
        this.router.events.subscribe(() => this.getParams());
    }

    getParams() {
        this.urlParamType = this.activatedRoute.snapshot.params.type;
        this.contentType = this.urlParamType.replace(/-/g, '');
    }

    donate(donationTarget: number) {
        this.firebaseService.donate(donationTarget).then(() => this.router.navigateByUrl('en/thanks'));
    }

    scrollTop() {
        window.scrollTo(0,0);
    }

    ngAfterViewInit() {
        this.scrollTop();
    }

}
