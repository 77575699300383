<div class="app__container container-fluid">
  <div class="row app__background">
    <div class="container px-vertical-center">
        <div class="row">
            <div class="col-12 text-center">
                <a routerLink="/" [ngClass]="{'app__isDisabled': this.router.url === '/thanks'}">
                    <img class="app__logo img-fluid" src="../assets/pixelgenau-consulting-gmbh-logo-white.png" />
                </a>
            </div>

            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <h1 class="text-center white">Donation campaign 2023 - your voice is needed!</h1>
                        <p class="text-center white">You decide which aid project we support. Increase our donation with your click!</p>
                    </div>
                </div>
                <section class="start__cards">
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="card">
                                <div class="card__image">
                                    <img class="img-fluid mw-80 w-aog" src="/assets/aerzte-ohne-grenzen.png" />
                                </div>
                                <div>
                                    <button class="button"
                                        *ngIf="firebaseService.voucherValid && firebaseService.voucherUsed === false"
                                        (click)="donate(DonationTarget.aerzteOhneGrenzen)">Donate</button>
                                    <button class="button button--trans"
                                        routerLink="/en/organisation/aerzte-ohne-grenzen">About Médecins Sans
                                        Frontières</button>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-4">
                            <div class="card">
                                <div class="card__image">
                                    <img class="img-fluid mw-80 w-uni" src="/assets/unicef.png" />
                                </div>
                                <div>
                                    <button class="button"
                                        *ngIf="firebaseService.voucherValid && firebaseService.voucherUsed === false"
                                        (click)="donate(DonationTarget.unicef)">Donate</button>
                                    <button class="button button--trans" routerLink="/en/organisation/unicef">About
                                        UNICEF</button>
                                </div>
                            </div>
                        </div>


                        <div class="col-12 col-lg-4">
                            <div class="card">
                                <div class="card__image">
                                    <img class="img-fluid max-9 mw-80 w-ft" src="/assets/frankfurter-tafel.png" />
                                </div>
                                <div>
                                    <button class="button"
                                        *ngIf="firebaseService.voucherValid && firebaseService.voucherUsed === false"
                                        (click)="donate(DonationTarget.frankfurterTafel)">Donate</button>
                                    <button class="button button--trans"
                                        routerLink="/en/organisation/frankfurter-tafel">About Frankfurter
                                        Tafel</button>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
            <div class="col-12">
                <app-footer-en></app-footer-en>
            </div>
        </div>
    </div>

  </div>
</div>

