import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartComponent } from './pages/start/start.component';
import { FooterComponent } from './components/footer/footer.component';
import { OrganisationDescriptionComponent } from './pages/organisation-description/organisation-description.component';
import { HttpClientModule } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ThanksComponent } from './pages/thanks/thanks.component';
import { StartEnComponent } from './pages/start-en/start-en.component';
import { ThanksEnComponent } from './pages/thanks-en/thanks-en.component';
import { OrganisationDescriptionEnComponent } from './pages/organisation-description-en/organisation-description-en.component';
import { FooterEnComponent } from './components/footer-en/footer-en.component';

@NgModule({
  declarations: [
    AppComponent,
    StartComponent,
    FooterComponent,
    OrganisationDescriptionComponent,
    ThanksComponent,
    StartEnComponent,
    ThanksEnComponent,
    OrganisationDescriptionEnComponent,
    FooterEnComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule, 
    AngularSvgIconModule.forRoot() 
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
