import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs';
import { DonationTarget } from 'src/app/interfaces/donations';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-organisation-description',
  templateUrl: './organisation-description.component.html',
  styleUrls: ['./organisation-description.component.scss']
})

export class OrganisationDescriptionComponent implements OnInit, AfterViewInit {

    DonationTarget = DonationTarget;
    urlParamType: string = '';
    contentType: string = '';

    constructor(private activatedRoute: ActivatedRoute, public router: Router, public firebaseService: FirebaseService) {
        this.getParams();
    }

    ngOnInit(): void {
        this.router.events.subscribe(() => this.getParams());
    }

    getParams() {
        this.urlParamType = this.activatedRoute.snapshot.params.type;
        this.contentType = this.urlParamType.replace(/-/g, '');
    }

    donate(donationTarget: number) {
        this.firebaseService.donate(donationTarget).then(() => this.router.navigateByUrl('/thanks'));
    }

    scrollTop() {
        window.scrollTo(0,0);
    }

    ngAfterViewInit() {
        this.scrollTop();
    }

}
