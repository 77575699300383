import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DonationTarget } from 'src/app/interfaces/donations';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})
export class StartComponent implements OnInit, AfterViewInit {

  DonationTarget = DonationTarget;
  voucher: null | string;

  constructor(public firebaseService: FirebaseService, public router: Router, private activatedRoute: ActivatedRoute) {

    this.voucher = this.activatedRoute.snapshot.queryParamMap.get('voucher') !== null ? this.activatedRoute.snapshot.queryParamMap.get('voucher') : '';
    
  }

  ngOnInit(): void {
    // this.router.navigateByUrl('/thanks');
    if (this.voucher) {
      this.firebaseService.checkVoucher(this.voucher).then(() => {
        if (this.firebaseService.voucherUsed) {
          this.router.navigateByUrl('/thanks');
        }
      });


    } else if (sessionStorage.getItem('voucher')) {
      const voucher: string = sessionStorage.getItem('voucher') && sessionStorage.getItem('voucher') !== null ? sessionStorage.getItem('voucher') + '' : '';
      this.firebaseService.checkVoucher(voucher).then(() => {
        if (this.firebaseService.voucherUsed) {
          this.router.navigateByUrl('/thanks');
        }
      });
    } else {
      this.router.navigateByUrl('/thanks');
    }
  }

  donate(donationTarget: number) {
    this.firebaseService.donate(donationTarget).then(() => this.router.navigateByUrl('/thanks'));
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

}
