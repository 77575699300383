<div class="app__container container-fluid">
    <div class="row app__background">
        <div class="container px-vertical-center">
            <div class="row">
                <div class="col-12 text-center">
                    <a routerLink="/" [ngClass]="{'app__isDisabled': this.router.url === '/thanks'}">
                        <img class="app__logo img-fluid" src="../assets/pixelgenau-consulting-gmbh-logo-white.png" />
                    </a>
                </div>

                <div class="col-12">
                    <ng-container [ngSwitch]="contentType">
                        <ng-container *ngSwitchCase="'aerzteohnegrenzen'">
                            <ng-container *ngTemplateOutlet="aerzteohnegrenzen"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'unicef'">
                            <ng-container *ngTemplateOutlet="unicef"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'frankfurtertafel'">
                            <ng-container *ngTemplateOutlet="frankfurtertafel"></ng-container>
                        </ng-container>
                        <div class="row">
                            <div class="col-12 text-center">
                                <h1 class="organisation-desciption__others-headline white">Our other donation projects</h1>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="organisation-desciption__others">
                                    <button *ngIf="contentType !== 'aerzteohnegrenzen'" (click)="scrollTop()"
                                        class="other-teaser" routerLink="/en/organisation/aerzte-ohne-grenzen">
                                        <div class="other-teaser__image">
                                            <img src="/assets/aerzte-ohne-grenzen.png" class="img-fluid" />
                                        </div>
                                        <div class="other-teaser__text">
                                            <p>Médecins Sans Frontières <span>></span></p>
                                        </div>
                                    </button>
                                    <button *ngIf="contentType !== 'frankfurtertafel'" (click)="scrollTop()"
                                        class="other-teaser" routerLink="/en/organisation/frankfurter-tafel">
                                        <div class="other-teaser__image">
                                            <img src="/assets/frankfurter-tafel.png" class="img-fluid" />
                                        </div>
                                        <div class="other-teaser__text">
                                            <p>Frankfurter Tafel <span>></span></p>
                                        </div>
                                    </button>
                                    <button *ngIf="contentType !== 'unicef'" (click)="scrollTop()" class="other-teaser"
                                        routerLink="/en/organisation/unicef">
                                        <div class="other-teaser__image">
                                            <img src="/assets/unicef.png" class="img-fluid" />
                                        </div>
                                        <div class="other-teaser__text">
                                            <p>Unicef <span>></span></p>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="col-12">
                    <app-footer-en></app-footer-en>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #aerzteohnegrenzen>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted card--inverse">
                <div class="card__text">
                    <h2>Médecins Sans Frontières</h2>
                    <p>As a humanitarian medical organisation, Médecins Sans Frontières is committed to providing high
                        quality
                        and efficient health care in countries where the survival of adults and children is at risk.
                        and children are at risk. <br>The medical projects around the world vary, depending on the
                        differ, depending on the initial situation and the needs of the patients, but they follow some
                        guidelines that have evolved over the years and have proven to be a dynamic framework.
                    </p>
                </div>
                <div class="card__image-half">
                    <div class="card__logo">
                        <img src="/assets/aerzte-ohne-grenzen.png" class="img-fluid mw-80 w-aog" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted">
                <div class="card__image-half">
                    <div style="background-image: url('assets/aerzte-ohne-grenzen-02.jpg')"></div>
                </div>
                <div class="card__text">
                    <p>Médecins Sans Frontières becomes active when the national health system is no longer willing or
                        able to help those affected.
                        is no longer willing or able to help those affected. This is usually the case in armed conflicts
                        as well as in the
                        the consequences of flight and displacement, epidemics and natural disasters.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted card--inverse-desktop">
                <div class="card__image-half">
                    <div style="background-image: url('assets/aerzte-ohne-grenzen-03.jpg')"></div>
                </div>
                <div class="card__text">
                    <p>
                        Since the survival of people is our top priority, life-saving activities are at the heart of our
                        work.
                        take centre stage. Medical treatment clearly takes precedence over prevention. Nevertheless
                        prevention is part of our activities wherever possible.
                    </p>
                    <div class="card__contact">
                        <button class="button"
                            *ngIf="firebaseService.voucherValid && firebaseService.voucherUsed === false"
                            (click)="donate(DonationTarget.aerzteOhneGrenzen)">Donate</button>
                        <a href="https://www.aerzte-ohne-grenzen.de/" target="_blank"><button
                                class="button button--trans">aerzte-ohne-grenzen.de</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #unicef>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted card--inverse">
                <div class="card__text">
                    <h2>Unicef</h2>
                    <p>UNICEF is the United Nations Children's Fund. The UNICEF's mission is to make children's rights a
                        reality for every child, regardless of colour
                        irrespective of skin colour, religion or origin. The guiding principle for its worldwide work is
                        the UN Convention on the Rights of the Child.</p>
                </div>
                <div class="card__image-half">
                    <div class="card__logo">
                        <img src="/assets/unicef.png" class="img-fluid mw-80 w-uni" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted">
                <div class="card__image-half">
                    <div style="background-image: url('assets/unicef-1.jpg')"></div>
                </div>
                <div class="card__text">
                    <p>Wars, disasters, diseases, exploitation or lack of participation: UNICEF is active in over
                        190 countries worldwide, helping to ensure that children are well cared for, can go to school
                        and are protected from violence.
                        are protected from violence. In Germany, too, UNICEF is active in raising awareness of
                        children's rights
                        and to contribute to their implementation - with political work, programmatic initiatives and
                        many volunteers.
                        many volunteers.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted card--inverse-desktop">
                <div class="card__image-half">
                    <div style="background-image: url('assets/unicef-2.jpg')"></div>
                </div>
                <div class="card__text">
                    <p>Through effective programmes and political influence, UNICEF has been making lasting progress for
                        children
                        for children - for over 70 years. UNICEF is as necessary today as it was when it was founded:
                        Never
                        since the Second World War have so many people been displaced. Over 250 million children
                        are growing up in conflict regions, and many more are threatened by natural disasters such as
                        droughts, floods or epidemics,
                        floods or epidemics.
                    </p>
                    <div class="card__contact">
                        <button class="button"
                            *ngIf="firebaseService.voucherValid && firebaseService.voucherUsed === false"
                            (click)="donate(DonationTarget.unicef)">Donate</button>
                        <a href="https://www.unicef.de/" target="_blank"><button
                                class="button button--trans">unicef.de</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #frankfurtertafel>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted card--inverse">
                <div class="card__text">
                    <h2>Frankfurter Tafel</h2>
                    <p>The Frankfurter Tafel e.V. is a non-profit private association that has set itself the task of
                        providing good
                        to help needy people who have a "Frankfurt Pass" in Frankfurt with good food.
                        with good food.</p>
                </div>
                <div class="card__image-half">
                    <div class="card__logo">
                        <img src="/assets/frankfurter-tafel.png" class="img-fluid mw-80 w-ft" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted">
                <div class="card__image-half">
                    <div style="background-image: url('assets/frankfurter-tafel-1.jpg')"></div>
                </div>
                <div class="card__text">
                    <p>The idea behind the table idea is impressively simple. Not all people have their
                        bread - and yet there is an abundance of food. On the one hand there is
                        On the one hand, there is food that can no longer be used in the economic process, but is still
                        qualitatively
                        are still of impeccable quality. On the other hand, there are needy people who can use this food
                        aid.
                        can use this food aid. The Tafeln are the bridge between poverty and abundance and strive with
                        volunteers to help them find a balance. In this way, the food banks help these people to bridge
                        a difficult
                        difficult times and give them motivation for the future.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted card--inverse-desktop">
                <div class="card__image-half">
                    <div style="background-image: url('assets/frankfurter-tafel-2.jpg')"></div>
                </div>
                <div class="card__text">
                    <p>The food banks distribute food that can no longer be used in the economic process,
                        but is still of good quality. E.g. rolls, bread and cakes from the day before, milk and dairy
                        products
                        milk and dairy products just before their expiry date, fruit with small blemishes, faulty
                        bottling, etc.
                        etc.<br>The collected food is passed on to people in need. Directly through
                        food to the needy - or indirectly, by supplying institutions that distribute
                        distributed food to needy people. The food is distributed free of charge or
                        for a symbolic amount.
                    </p>
                    <div class="card__contact">
                        <button class="button"
                            *ngIf="firebaseService.voucherValid && firebaseService.voucherUsed === false"
                            (click)="donate(DonationTarget.frankfurterTafel)">Donate</button>
                        <a href="https://www.frankfurter-tafel.de/" target="_blank"><button
                                class="button button--trans">frankfurter-tafel.de</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
