import { Component } from '@angular/core';
import { Router } from '@angular/router'
import { FirebaseService } from './services/firebase.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Pixelgenau Consulting GmbH Spendenaktion 2023';


  constructor(
    public firebaseService: FirebaseService,
    public router: Router
  ) {

  }

  async ngOnInit() {
  }
}
