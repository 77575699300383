import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CheckResult, DonationTargetString, Statistics } from '../interfaces/donations';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  DonationTargetString = DonationTargetString;

  private firebaseUrls = environment.firbaseApiUrl
    ? environment.firbaseApiUrl
    : 'https://us-central1-pxc-donation.cloudfunctions.net';
  public voucherUsed = true;
  public voucherValid = false;
  public voucherCode = '';
  public donationTarget = 0;
  public donationTargetString = '';


  public statistic: Statistics = {
    sum: 0,
    ffmTafelPercent: 0,
    ffmTafelTotal: 0,
    medicinsSFPercent: 0,
    medicinsSFTotal: 0,
    unicefPercent: 0,
    unicefTotal: 0
  };

  constructor(private http: HttpClient) {
    this.setStatistic();
  }

  async setStatistic() {
    const url = this.firebaseUrls + '/getStatistics';
    this.statistic = await this.http.get<Statistics>(url).toPromise();
  }

  async checkVoucher(voucher: string) {
    this.voucherCode = voucher;
    const url =
      this.firebaseUrls + '/checkVoucherCode?vouchercode=' + voucher;

    const voucherCheckResult: CheckResult = await this.http.get<CheckResult>(url).toPromise();
    this.voucherValid = voucherCheckResult?.result ? voucherCheckResult?.result : false;
    this.voucherUsed = voucherCheckResult?.used === false ? voucherCheckResult?.used : true;
    this.donationTarget = voucherCheckResult?.donationTarget ? voucherCheckResult?.donationTarget : 0;
    this.donationTargetString = voucherCheckResult?.donationTarget ? DonationTargetString[voucherCheckResult?.donationTarget] : '';
    sessionStorage.setItem('voucher', voucher);
  }

  async setVoucherCodeAsUsed(donationTarget: number) {
    const url =
      this.firebaseUrls +
      '/setVoucherCodeAsUsed?vouchercode=' +
      this.voucherCode +
      '&donationtarget=' + donationTarget;
    await this.http.get(url).toPromise();
    await this.checkVoucher(this.voucherCode);
    this.setStatistic();
  }

  async donate(target: number) {
    this.setVoucherCodeAsUsed(target);
  }
}
