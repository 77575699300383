import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DonationTarget } from 'src/app/interfaces/donations';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-thanks-en',
  templateUrl: './thanks-en.component.html',
  styleUrls: ['./thanks-en.component.scss']
})
export class ThanksEnComponent implements OnInit {

  rest: number = 0;
  DonationTarget = DonationTarget;
  voucher: null | string;

  constructor(public firebaseService: FirebaseService, public router: Router, private activatedRoute: ActivatedRoute) {
    this.voucher = this.activatedRoute.snapshot.queryParamMap.get('voucher') !== null ? this.activatedRoute.snapshot.queryParamMap.get('voucher') : '';
  }

  ngOnInit(): void {
    console.log("Statisctics", this.firebaseService.statistic)
    if (this.voucher) {
      this.firebaseService.checkVoucher(this.voucher).then(() => {});
    } else if (sessionStorage.getItem('voucher')) {
      const voucher: string = sessionStorage.getItem('voucher') && sessionStorage.getItem('voucher') !== null ? sessionStorage.getItem('voucher') + '' : '';
      this.firebaseService.checkVoucher(voucher).then(() => {});
    }
  }

  ngAfterViewChecked() {
    this.rest = 100 - Math.round(this.firebaseService.statistic.ffmTafelPercent) - Math.round(this.firebaseService.statistic.unicefPercent) - Math.round(this.firebaseService.statistic.medicinsSFPercent);
    console.log("rst", this.rest)
    if(this.rest === -1) {
      this.firebaseService.statistic.ffmTafelPercent = Math.round(this.firebaseService.statistic.ffmTafelPercent) - 1;
    } else if (this.rest === 1) {
      this.firebaseService.statistic.ffmTafelPercent = Math.round(this.firebaseService.statistic.ffmTafelPercent) + 1;
    }
  }

  getDonationTarget(value: number) {
    console.log("Donation", DonationTarget[value])
  }

  ngAfterViewInit() {
    window.scrollTo(0, 0);
  }

}
