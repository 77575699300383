export interface Statistics {
    sum: number;
    ffmTafelTotal: number;
    ffmTafelPercent: number;
    unicefTotal: number;
    unicefPercent: number;
    medicinsSFTotal: number;
    medicinsSFPercent: number;

}

export interface CheckResult {
    result: boolean;
    donationTarget: number;
    used: boolean;


}

export enum DonationTarget {
    frankfurterTafel = 1,
    unicef = 2,
    aerzteOhneGrenzen = 3
}

export enum DonationTargetString {
    'Frankfurter Tafel' = 1,
    'UNICEF' = 2,
    'Ärzte ohne Grenzen' = 3
}

