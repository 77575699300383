import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OrganisationDescriptionEnComponent } from './pages/organisation-description-en/organisation-description-en.component';
import { OrganisationDescriptionComponent } from './pages/organisation-description/organisation-description.component';
import { StartEnComponent } from './pages/start-en/start-en.component';
import { StartComponent } from './pages/start/start.component';
import { ThanksEnComponent } from './pages/thanks-en/thanks-en.component';
import { ThanksComponent } from './pages/thanks/thanks.component';

const routes: Routes = [
  {
    path: '',
    component: StartComponent,
  },
  {
    path: 'organisation',
    component: OrganisationDescriptionComponent,
  },
  {
    path: 'organisation/:type',
    component: OrganisationDescriptionComponent,
  },
  {
    path: 'thanks',
    component: ThanksComponent,
  },
  {
    path: 'en',
    component: StartEnComponent,
  },
  {
    path: 'en/organisation',
    component: OrganisationDescriptionEnComponent,
  },
  {
    path: 'en/organisation/:type',
    component: OrganisationDescriptionEnComponent,
  },
  {
    path: 'en/thanks',
    component: ThanksEnComponent,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
