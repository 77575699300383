<div class="app__container container-fluid">
  <div class="row app__background">
    <div class="container px-vertical-center">
        <div class="row">
            <div class="col-12 text-center">
                <a routerLink="/" [ngClass]="{'app__isDisabled': this.router.url === '/thanks'}">
                    <img class="app__logo img-fluid" src="../assets/pixelgenau-consulting-gmbh-logo-white.png" />
                </a>
            </div>

            <div class="col-12">
                <ng-container [ngSwitch]="contentType">
                    <ng-container *ngSwitchCase="'aerzteohnegrenzen'">
                        <ng-container *ngTemplateOutlet="aerzteohnegrenzen"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'unicef'">
                        <ng-container *ngTemplateOutlet="unicef"></ng-container>
                    </ng-container>
                    <ng-container *ngSwitchCase="'frankfurtertafel'">
                        <ng-container *ngTemplateOutlet="frankfurtertafel"></ng-container>
                    </ng-container>
                    <div class="row">
                        <div class="col-12 text-center">
                            <h1 class="organisation-desciption__others-headline white">Unsere anderen Spendenprojekte</h1>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="organisation-desciption__others">
                                <button *ngIf="contentType !== 'aerzteohnegrenzen'" (click)="scrollTop()"
                                    class="other-teaser" routerLink="/organisation/aerzte-ohne-grenzen">
                                    <div class="other-teaser__image">
                                        <img src="/assets/aerzte-ohne-grenzen.png" class="img-fluid" />
                                    </div>
                                    <div class="other-teaser__text">
                                        <p>Ärzte ohne Grenzen <span>></span></p>
                                    </div>
                                </button>
                                <button *ngIf="contentType !== 'frankfurtertafel'" (click)="scrollTop()"
                                    class="other-teaser" routerLink="/organisation/frankfurter-tafel">
                                    <div class="other-teaser__image">
                                        <img src="/assets/frankfurter-tafel.png" class="img-fluid" />
                                    </div>
                                    <div class="other-teaser__text">
                                        <p>Frankfurter Tafel <span>></span></p>
                                    </div>
                                </button>
                                <button *ngIf="contentType !== 'unicef'" (click)="scrollTop()" class="other-teaser"
                                    routerLink="/organisation/unicef">
                                    <div class="other-teaser__image">
                                        <img src="/assets/unicef.png" class="img-fluid" />
                                    </div>
                                    <div class="other-teaser__text">
                                        <p>Unicef <span>></span></p>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="col-12">
                <app-footer></app-footer>
            </div>
        </div>
    </div>

  </div>
</div>

<ng-template #aerzteohnegrenzen>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted card--inverse">
                <div class="card__text">
                    <h2>Ärzte ohne Grenzen</h2>
                    <p>Als humanitäre medizinische Organisation setzt sich Ärzte ohne Grenzen für eine qualitativ
                        hochwertige und effiziente Gesundheitsversorgung in den Ländern ein, in denen das Überleben von
                        Erwachsenen und Kindern gefährdet ist.<br>Die medizinischen Projekte weltweit unterscheiden
                        sich zwar, abhängig von der Ausgangslage und dem Bedarf der Patientinnen und Patienten, folgen
                        aber einigen Leitlinien, die sich über die Jahre entwickelt und als dynamisches Gerüst erwiesen
                        haben.</p>
                </div>
                <div class="card__image-half">
                    <div class="card__logo">
                        <img src="/assets/aerzte-ohne-grenzen.png" class="img-fluid mw-80 w-aog" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted">
                <div class="card__image-half">
                    <div style="background-image: url('assets/aerzte-ohne-grenzen-03.jpg')"></div>
                </div>
                <div class="card__text">
                    <p>Ärzte ohne Grenzen wird aktiv, wenn das nationale Gesundheitssystem nicht mehr willig oder in der
                        Lage ist, den Betroffenen zu helfen. Dies ist meist der Fall bei bewaffneten Konflikten sowie
                        den Folgen von Flucht und Vertreibung, bei Epidemien und Naturkatastrophen.<br>
                        Da das Überleben der Menschen oberste Priorität für uns hat, stehen lebensrettende Aktivitäten
                        im Mittelpunkt. Die medizinische Behandlung hat eindeutig Vorrang vor der Prävention. Gleichwohl
                        gehört Vorbeugung, wo immer möglich, zu unseren Aktivitäten.
                    </p>
                    <div class="card__contact">
                        <button class="button"
                            *ngIf="firebaseService.voucherValid && firebaseService.voucherUsed === false"
                            (click)="donate(DonationTarget.aerzteOhneGrenzen)">Spenden</button>
                        <a href="https://www.aerzte-ohne-grenzen.de/" target="_blank"><button
                                class="button button--trans">aerzte-ohne-grenzen.de</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #unicef>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted card--inverse">
                <div class="card__text">
                    <h2>Unicef</h2>
                    <p>UNICEF ist das Kinderhilfswerk der Vereinten Nationen (United Nations Children’s Fund). Der
                        Auftrag von UNICEF ist es, die Kinderrechte für jedes Kind zu verwirklichen, unabhängig von
                        seiner Hautfarbe, Religion oder Herkunft. Richtschnur für die weltweite Arbeit ist die
                        UN-Konvention über die Rechte des Kindes.</p>
                </div>
                <div class="card__image-half">
                    <div class="card__logo">
                        <img src="/assets/unicef.png" class="img-fluid mw-80 w-uni" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted">
                <div class="card__image-half">
                    <div style="background-image: url('assets/unicef-1.jpg')"></div>
                </div>
                <div class="card__text">
                    <p>Kriege, Katastrophen, Krankheiten, Ausbeutung oder fehlende Teilhabe: UNICEF ist weltweit in über
                        190 Ländern im Einsatz und hilft, dass Kinder gut versorgt werden, zur Schule gehen können und
                        vor Gewalt geschützt sind. Auch in Deutschland ist UNICEF aktiv, um Kinderrechte bekanntzumachen
                        und zu ihrer Durchsetzung beizutragen – mit politischer Arbeit, programmatischen Initiativen und
                        vielen ehrenamtlich Engagierten.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted card--inverse-desktop">
                <div class="card__image-half">
                    <div style="background-image: url('assets/unicef-2.jpg')"></div>
                </div>
                <div class="card__text">
                    <p>Mit wirksamen Programmen und politischer Einflussnahme erzielt UNICEF nachhaltige Fortschritte
                        für Kinder – seit über 70 Jahren. UNICEF ist heute so notwendig wie zu seiner Gründung: Noch nie
                        seit dem Zweiten Weltkrieg waren so viele Menschen auf der Flucht. Über 250 Millionen Kinder
                        wachsen in Konfliktregionen auf, noch viel mehr sind von Naturkatastrophen wie Dürren,
                        Überschwemmungen oder Epidemien bedroht.
                    </p>
                    <div class="card__contact">
                        <button class="button"
                            *ngIf="firebaseService.voucherValid && firebaseService.voucherUsed === false"
                            (click)="donate(DonationTarget.unicef)">Spenden</button>
                        <a href="https://www.unicef.de/" target="_blank"><button
                                class="button button--trans">unicef.de</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #frankfurtertafel>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted card--inverse">
                <div class="card__text">
                    <h2>Frankfurter Tafel</h2>
                    <p>Die Frankfurter Tafel e.V. ist ein gemeinnütziger privater Verein, der sich die Aufgabe gestellt
                        hat, bedürftigen Mitmenschen, die in Frankfurter einen "Frankfurt-Pass" besitzen, mit guten
                        Lebensmitteln zu helfen.</p>
                </div>
                <div class="card__image-half">
                    <div class="card__logo">
                        <img src="/assets/frankfurter-tafel.png" class="img-fluid mw-80 w-ft" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted">
                <div class="card__image-half">
                    <div style="background-image: url('assets/frankfurter-tafel-1.jpg')"></div>
                </div>
                <div class="card__text">
                    <p>Die Idee, die hinter der Tafelidee steckt, ist bestechend einfach. Nicht alle Menschen haben ihr
                        täglich Brot – und dennoch gibt es Lebensmittel im Überfluß. Auf der einen Seite gibt es
                        Lebensmittel, die im Wirtschaftsprozess nicht mehr verwendet werden können, aber qualitativ noch
                        einwandfrei sind. Auf der anderen Seite gibt es Bedürftige, die diese Lebensmittelhilfe
                        gebrauchen können. Die Tafeln sind die Brücke zwischen Armut und Überfluß und bemühen sich mit
                        ehrenamtlichen Helfern für einen Ausgleich. Die Tafeln helfen so diesen Menschen eine schwierige
                        Zeit zu überbrücken und geben ihnen dadurch Motivation für die Zukunft.
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card card--splitted card--inverse-desktop">
                <div class="card__image-half">
                    <div style="background-image: url('assets/frankfurter-tafel-2.jpg')"></div>
                </div>
                <div class="card__text">
                    <p>Die Tafeln verteilen Lebensmittel, die im Wirtschaftsprozeß nicht mehr verwendet werden können,
                        aber qualitativ noch einwandfrei sind. Z.B. Brötchen, Brot und Kuchen vom Vortag, Milch und
                        Milchprodukte kurz vor dem Verfallsdatum, Obst mit kleinen Schönheitsfehlern, Fehlabfüllungen
                        usw.<br>Die gesammelten Lebensmittel werden weitergereicht an bedürftige Personen. Direkt durch
                        Lebensmittelausgaben an Bedürftige – oder indirekt, indem Einrichtungen beliefert werden, die
                        Essen an bedürftige Menschen ausgegeben. Die Abgabe der Lebensmittel erfolgt kostenlos oder
                        gegen einen symbolischen Betrag.
                    </p>
                    <div class="card__contact">
                        <button class="button"
                            *ngIf="firebaseService.voucherValid && firebaseService.voucherUsed === false"
                            (click)="donate(DonationTarget.frankfurterTafel)">Spenden</button>
                        <a href="https://www.frankfurter-tafel.de/" target="_blank"><button
                                class="button button--trans">frankfurter-tafel.de</button></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
