<div class="app__container container-fluid">
    <div class="row app__background">
        <div class="app__overlay"></div>
        <div class="container">
            <div class="row">

                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <h1 class="text-center white">Vielen Dank für Ihre Unterstützung!</h1>
                            <p *ngIf="firebaseService.donationTargetString" class="text-center white">
                                Mit Ihrer Stimme haben Sie den Spendenbetrag an das Projekt <strong>{{
                                    firebaseService.donationTargetString }}</strong> erhöht!
                                <br><br class="mobile">
                                <strong>Aktuell verteilt sich der Spendenbetrag wie folgt:</strong>
                            </p>
                        </div>
                    </div>

                    <div class="row rating">

                        <div class="col-12 col-lg-4">
                            <div class="card">
                                <div class="rating__check"
                                    *ngIf="firebaseService.donationTarget == DonationTarget.aerzteOhneGrenzen">
                                    <svg-icon src="/assets/check.svg"></svg-icon>
                                </div>
                                <div class="card__image">
                                    <img class="img-fluid max-9 mw-80 w-aog" src="/assets/aerzte-ohne-grenzen.png" />
                                </div>
                                <p class="rating__percentage desktop"></p>
                                <p class="rating__text desktop text-center">{{ firebaseService.statistic.medicinsSFPercent | number:'1.0-0' }}% aller Stimmen</p>
                                <div class="mobile">
                                    <div class="d-flex justify-content-between">
                                        <p>Ärzte ohne Grenzen</p>
                                        <p class="text-right"><br /><span class="fs-small">{{ firebaseService.statistic.medicinsSFPercent | number:'1.0-0' }}% aller Stimmen</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 col-lg-4">
                            <div class="card">
                                <div class="rating__check"
                                    *ngIf="firebaseService.donationTarget == DonationTarget.unicef">
                                    <svg-icon src="/assets/check.svg"></svg-icon>
                                </div>
                                <div class="card__image">
                                    <img class="img-fluid max-9 mw-80 w-uni" src="/assets/unicef.png" />
                                </div>
                                <p class="rating__percentage desktop"></p>
                                <p class="rating__text desktop text-center">{{ firebaseService.statistic.unicefPercent | number:'1.0-0' }}% aller Stimmen</p>
                                <div class="mobile">
                                    <div class="d-flex justify-content-between">
                                        <p>UNICEF</p>
                                        <p class="text-right"><br /><span class="fs-small">{{ firebaseService.statistic.unicefPercent | number:'1.0-0' }}% aller Stimmen</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <div class="col-12 col-lg-4">
                            <div class="card">
                                <div class="rating__check"
                                    *ngIf="firebaseService.donationTarget == DonationTarget.frankfurterTafel">
                                    <svg-icon src="/assets/check.svg"></svg-icon>
                                </div>
                                <div class="card__image">
                                    <img class="img-fluid max-9 mw-80 w-ft" src="/assets/frankfurter-tafel.png" />
                                </div>
                                <p class="rating__percentage desktop"></p>
                                <p class="rating__text desktop text-center">{{ firebaseService.statistic.ffmTafelPercent | number:'1.0-0' }}% aller Stimmen</p>
                                <div class="mobile">
                                    <div class="d-flex justify-content-between">
                                        <p>Frankfurter Tafel</p>
                                        <p class="text-right"><br /><span class="fs-small">{{ firebaseService.statistic.ffmTafelPercent | number:'1.0-0' }}% aller Stimmen</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-12 text-center mt-3">
                            <a href="https://www.pixelgenau.com/de/home" target="_blank">
                                <img class="app__logo img-fluid" src="../assets/pixelgenau-consulting-gmbh-logo-white.png" />
                            </a>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12 text-center mt-3 mb-5">
                            <img src="/assets/awards-german-design-2021.png" height="50" />
                            &nbsp;
                            <img src="/assets/awards-german-design-2022.png" height="50" />
                            &nbsp;
                            <img src="/assets/GDA23_HO_SPECIAL_MCN_RGB.png" height="50" />
                            &nbsp;
                            <img src="/assets/GDA24_HO_WINNER_MCN_4C.png" height="50" />
                            &nbsp;
                            <img src="/assets/kununu_top_company_2024.png" height="50" />
                        </div>
                    </div>

                </div>
                <div class="col-12">
                    <app-footer></app-footer>
                </div>
            </div>
        </div>

    </div>
</div>
